<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    class="elevation-1 text-no-wrap"
    mobile-breakpoint="10"
    :footer-props="{
      'items-per-page-text':'',
      'items-per-page-all-text':'Alla'
    }"
    @click:row="itemSelected"
  >
    <template #top>
      <v-toolbar
        flat
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Fritext sökning"
          single-line
          hide-details
        />
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { sync } from 'vuex-pathify'
import ApiService from '@/services/api.service'
export default {
  name: 'UsersList',

  data: () => ({
    headers: [
      { text: 'Id', align: 'start', value: 'username', width: '10%' },
      { text: 'Tillstånd', align: 'start', value: 'state', width: '60%' }
    ],
    items: [],
    data: [],
    search: ''
  }),

  computed: {
    ...sync('app', ['firstuse', 'user'])
  },

  created () {
    this.refresh()
  },

  methods: {
    initialize () {},

    refresh () {
      this.loading = true
      ApiService.get('data/users')
        .then(response => {
          this.data = response.data
          this.items = this.data;
        }).catch(response => {
          this.$notification.error('Failed to get work orders: ' + response)
        })
    },

    itemSelected (item) {
        this.$router.push({ name: 'viewuser', params: { uid: item.id } })
      },
  }
}
</script>
