<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('@/assets/logo-notext.png')"
          class="my-3"
          contain
          height="100"
        />
      </v-col>

      <v-col class="mb-4">
        <p class="subheading font-weight-regular">
          Använd denna app för att rapportera in utförda arbetsordrar och labbrapporter.
        </p>
        <p class="subheading font-weight-regular">
          Välj formulär för inrapportering längst ned.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',

  data: () => ({
  })
}
</script>
