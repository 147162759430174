import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins'
import ApiService from './services/api.service'
import VueNotification from '@kugatsu/vuenotification'

ApiService.init()

Vue.config.productionTip = false
Vue.use(VueNotification, {
  timer: 5,
  showCloseIcn: true,
})

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load

  if (to.name === null) {
    next(false)
    return
  }

  console.log('store.state.app.token: ' + store.state.app.token)
  
  // if (store.state.app.token === '') {
  //   console.log('to.name: ' + to.name)
  //   if (to.name === 'authenticate') {
  //     next(true)
  //     return
  //   }

  //   if (to.name !== 'nouse') {
  //     if (from.name === 'nouse') {
  //       next(false)
  //       return
  //     }

  //     router.push('/nouse')
  //   }
  // } else if (store.state.app.firstuse) {
  //   if (to.name !== 'play-firstuse') {
  //     if (from.name === 'play-firstuse') {
  //       next(false)
  //       return
  //     }
      
  //     router.push('/play/firstuse')
  //   }
  // }
  next()
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
