<template>
  <v-card>
    <v-card-title>Markör</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="editeditem.name"
        label="Namn"
        required
      />
      <v-textarea
        v-model="editeditem.description"
        label="Beskrivning"
        required
      />
      <picture-item 
        :image="image"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { sync } from 'vuex-pathify'
import ApiService from '@/services/api.service'
// import PictureItem from '@/components/widgets/PictureItem.vue'

export default {
  name: 'PlayMarkerItem',

  // components: {
  //   PictureItem,
  // },

  data: () => ({
    imageresource: {id: 0},
    image: '',
  }),

  computed: {
    ...sync('play', ['event', 'map', 'marker'])
  },

  created () {
    this.refresh()
    console.log('marker item created, item: ' + JSON.stringify(this.marker))
    this.editeditem = this.marker
  },

  methods: {
    initialize () {},

    refresh () {
      ApiService.get('resource/marker/' + this.marker.id + '/mainclue')
        .then(response => {
          if (response.data?.length > 0) {
            this.imageresource = response.data[0]
            this.image = response.data[0].text;
          }
        }).catch(response => {
          this.$notification.error('Failed to get resources: ' + response)
        })
    },

    onNewQRCode (newcode) {
      this.marker.code = newcode
    },

    async onCapture (image) {
      console.log('captured image: ' + image)
      if (this.marker.id > 0) {
        if (this.imageresource.id === 0) {
          this.imageresource = { type: 'mainclue', markerid: this.marker.id }
        }

        this.imageresource.text = image
        ApiService.put('data/resources', this.imageresource)
          .then(response => {
            this.$notification.success('Bild sparad')
            this.imageresource = response.data
            this.marker.image_id = this.imageresource.id
          }).catch(response => {
            this.$notification.error('Kunde inte spara: ' + response)
          })
      }
    }
  }
}
</script>
