<template>
  <v-card>
    <v-card-title>Resurs</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="editeditem.name"
        label="Namn"
        required
      />
      <v-textarea
        v-model="editeditem.description"
        label="Beskrivning"
        required
      />
    </v-card-text>
    <v-card-actions>
      <v-row justify="space-around" class="mb-3">
        <v-btn 
          text
          @click="$router.go(-1)"
        >
          Avbryt
        </v-btn>
        <v-btn 
          text
          color="error"
          @click="deleteItem()"
        >
          Ta bort
        </v-btn>
        <v-btn
          text
          color="success"
          @click="saveItem()"
        >
          Spara
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { sync } from 'vuex-pathify'
import ApiService from '@/services/api.service'

export default {
  name: 'ResourceItem',

  components: {
  },

  data: () => ({
    editeditem: {}
  }),

  computed: {
    ...sync('create', ['event', 'map', 'marker', 'resource'])
  },

  created () {
    this.refresh()
    console.log('resource item created, item: ' + JSON.stringify(this.marker))
    this.editeditem = this.resource
  },

  methods: {
    initialize () {},

    refresh () {
    },
    
    saveItem () {
      this.editeditem.markerid = this.marker.id
      if (this.editeditem.code === '') this.editeditem.code = ApiService.generateId()
      console.log('saving item: ' + JSON.stringify(this.editeditem))
      ApiService.put('data/resources', this.editeditem)
        .then(response => {
          console.log('response: ' + JSON.stringify(response.data))
          this.$notification.success('Sparat')
        }).catch(response => {
          this.$notification.error('Kunde inte spara: ' + response)
        })
    },
    
    deleteItem () {
      console.log('deleting item: ' + JSON.stringify(this.item))
      ApiService.delete('data/resources/' + this.item.id)
        .then(response => {
          console.log('response: ' + JSON.stringify(response.data))
          this.$notification.success('Borttaget')
          this.$router.go(-1)
        }).catch(response => {
          this.$notification.error('Misslyckades med att ta bort: ' + response)
        })
    },
  }
}
</script>
