import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginView from '@/views/create/LoginView'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: () => import('../views/HomeView.vue') },

  { path: '/create', name: 'create-home', component: () => import('../views/create/HomeView.vue') },
  { path: '/create/login', name: 'login', component: LoginView },
  { path: '/create/authenticate/:code', name: 'authenticate', component: () => import('../views/create/AuthenticateView.vue') },
  { path: '/create/firstuse', name: 'create-firstuse', component: () => import('../views/create/FirstUseView.vue') },
  { path: '/create/users', name: 'users', component: () => import('../views/create/UsersView.vue') },
  { path: '/create/viewuser/:uid', name: 'viewuser', component: () => import('../views/create/UserView.vue') },
  { path: '/create/newuser', name: 'newuser', component: () => import('../views/create/UserView.vue') },
  { path: '/create/qrcode', name: 'qrcode', component: () => import('../views/create/QRCodeView.vue') },
  { path: '/create/settings', name: 'settings', component: () => import('../views/create/SettingsView.vue') },
  { path: '/create/event', name: 'viewevent', component: () => import('../views/create/events/EventView.vue') },
  { path: '/create/map', name: 'viewmap', component: () => import('../views/create/maps/MapView.vue') },
  { path: '/create/marker', name: 'viewmarker', component: () => import('../views/create/markers/MarkerView.vue') },
  { path: '/create/resource', name: 'viewresource', component: () => import('../views/create/resources/ResourceView.vue') },
  { path: '/create/qrcodes', name: 'createqrcodes', component: () => import('../views/create/qrcodes/CreateQRCodesView.vue') },

  { path: '/play/about', name: 'about', component: () => import('../views/play/AboutView.vue') },
  { path: '/play/nouse', name: 'nouse', component: () => import('../views/play/NoUseView.vue') },
  { path: '/play/firstuse', name: 'play-firstuse', component: () => import('../views/play/FirstUseView.vue') },
  { path: '/play/help', name: 'help', component: () => import('../views/play/HelpView.vue') },
  { path: '/play/event/:code', name: 'playevent', component: () => import('../views/play/PlayEventView.vue') },
  { path: '/play/map/:code', name: 'playmap', component: () => import('../views/play/PlayMapView.vue') },
  { path: '/play/marker', name: 'playmarker', component: () => import('../views/play/PlayMarkerView.vue') },
  { path: '/play/treasure', name: 'treasure', component: () => import('../views/play/TreasureView.vue') },

  { path: '/common/capturecamera', name: 'capturecamera', props: true, component: () => import('../views/common/CameraCaptureView.vue') },
  { path: '/test/test1', name: 'testview', props: true, component: () => import('../views/test/TestView.vue') },
]

const router = new VueRouter({
  routes
})

export default router
