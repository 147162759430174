<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-1 text-no-wrap"
      mobile-breakpoint="10"
      :footer-props="{
        'items-per-page-text':'',
        'items-per-page-all-text':'Alla'
      }"
      @click:row="itemSelected"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Fritext sökning"
            single-line
            hide-details
          />
          <v-spacer />          
          <v-btn
            color="cyan accent-2"
            @click="onNewClick"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'
import ApiService from '@/services/api.service'
export default {
  name: 'ResourceList',

  data: () => ({
    headers: [
      { text: 'Resurser', align: 'start', value: 'name', width: '60%' }
    ],
    items: [],
    search: ''
  }),

  computed: {
    ...sync('create', ['event', 'map', 'marker', 'resource'])
  },

  created () {
    this.refresh()
    console.log("resource list created, marker: " + JSON.stringify(this.marker))
  },

  methods: {
    initialize () {},

    refresh () {
      // if (this.marker.id > 0) {
      //   ApiService.get('data/resources/field/marker_id/' + this.marker.id)
      //     .then(response => {
      //       console.log(response.data.length + ' resources fetched')
      //       this.marker.resources = response.data
      //       this.items = this.marker.resources;
      //     }).catch(response => {
      //       this.$notification.error('Failed to get resources: ' + response)
      //     })
      //   }
    },

    itemSelected (item) {
      this.resource = item
      console.log('list item clicked: ' + JSON.stringify(this.resource))
      this.$router.push({ name: 'viewresource' })
    },

    onNewClick () {
      this.resource = {}
      this.$router.push({ name: 'viewresource' })
    },
  }
}
</script>
