<template>
  <v-img
    :src="qr"
    max-width="120"
  />
</template>

<script>
import defaultQr from '@/assets/logo-notext.png'
import QRCode from 'qrcode'
export default {
  name: 'QrCodeView',

  props: {
    code: {
      type: String,
      default: () => (''),
    }
  },

  data: () => ({
    qr: defaultQr
  }),

  created () {
    this.refresh()
  },

  methods: {
    initialize () {},

    async refresh (user) {
      let code = this.code
      this.qr = await QRCode.toDataURL(code)
    }
  }
}
</script>

<style>
  .v-image {
    -webkit-print-color-adjust: exact;
  }
</style>