<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-1 text-no-wrap"
      mobile-breakpoint="10"
      :footer-props="{
        'items-per-page-text':'',
        'items-per-page-all-text':'Alla'
      }"
      @click:row="itemSelected"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Fritext sökning"
            single-line
            hide-details
          />
          <v-spacer />          
          <v-btn
            color="cyan accent-2"
            @click="onNewClick"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'
import ApiService from '@/services/api.service'
export default {
  name: 'MarkerList',

  data: () => ({
    headers: [
      { text: 'Id', align: 'start', value: 'id', width: '100px' },
      { text: 'Markörer', align: 'start', value: 'name', width: '60%' }
    ],
    items: [],
    search: ''
  }),

  computed: {
    ...sync('create', ['event', 'map', 'marker'])
  },

  created () {
    this.refresh()
    console.log("marker list created, map: " + JSON.stringify(this.map))
  },

  methods: {
    initialize () {},

    refresh () {
      // Update list every time
      if (this.map.id > 0) {
        ApiService.get('data/markers/field/map_id/' + this.map.id)
            .then(response => {
              console.log(response.data.length + ' markers fetched')
              this.map.markers = response.data
              this.items = this.map.markers.sort(function(a,b) {a.order - b.order});
            }).catch(response => {
              this.$notification.error('Failed to get markers: ' + response)
            })
      }
    },

    itemSelected (item) {
      this.marker = item
      console.log('list item clicked: ' + JSON.stringify(this.marker))
      this.$router.push({ name: 'viewmarker' })
    },

    onNewClick () {
      this.marker = {}
      this.$router.push({ name: 'viewmarker' })
    },
  }
}
</script>
