<template>
  <v-card>
    <v-card-text>
      <v-text-field
        v-model="newcode"
        label="Kod"
        required
      />
      <v-btn
        v-if="!readonly"
        color="primary"
        block
        class="mb-2"
        @click="dialog = !dialog"
      >
        SKanna kod
      </v-btn>
      <v-img
        v-if="code !== ''"
        contain
        max-height="300"
        :src="qr"
      />
    </v-card-text>
    <v-dialog
      v-model="dialog"
    >
      <QrcodeStream
        :camera="camera"
        @decode="onDecode"
        @init="onInit"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { sync } from 'vuex-pathify'
import defaultQr from '@/assets/logo-notext.png'
import QRCode from 'qrcode'
import { QrcodeStream } from 'vue-qrcode-reader'
export default {
  name: 'QrCodeItem',

  components: {
    QrcodeStream,
  },

  props: {
    code: {
      type: String,
      default: () => (''),
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    url: window.location.origin,
    qr: defaultQr,
    camera: 'rear',
    newcode: '',
    dialog: false,
  }),

  computed: {
    ...sync('create', ['event', 'map', 'marker']),
  },

  watch: {
    code: function () {
      this.refresh()
    },
    dialog: function() {
      if ( this.dialog === false ) this.camera = 'off'
    }
  },

  created () {
    this.camera = 'rear'
    this.newcode = this.code
    this.refresh()
  },

  methods: {
    initialize () {},

    async refresh () {
      if (this.newcode !== '') {
        this.qr = await QRCode.toDataURL(this.newcode)
        console.log('code: ' + this.code)
      }
    },

    onDecode(decodedString) {
      this.newcode = decodedString
      // this.marker.code = decodedString
      this.$emit('code-changed', this.newcode)
      this.dialog = false
      this.camera = 'off'
      this.refresh()
    },

    onInit(promise) {
      promise
        .then(console.log)
        .catch(console.error)
    }
  }
}
</script>
