<template>
  <v-card>
    <v-card-title>Event</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="editeditem.name"
        label="Namn"
        required
      />
      <v-textarea
        v-model="editeditem.description"
        label="Beskrivning"
        required
      />
      <v-card-actions>
        <v-row justify="space-around" class="mb-3">
          <v-btn
            @click="$router.go(-1)"
          >
            Avbryt
          </v-btn>
          <v-btn
            color="error"
            @click="deleteItem()"
          >
            Ta bort
          </v-btn>
          <v-btn
            color="success"
            @click="saveItem()"
          >
            Spara
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card-text>
    <qr-code-item
      v-if="editeditem.code"
      :code="eventurl"
      readonly
    />
  </v-card>
</template>

<script>
import { sync } from 'vuex-pathify'
import ApiService from '@/services/api.service'

export default {
  name: 'EventItem',

  components: {
  },

  data: () => ({
    editeditem: {},
    eventurl: window.location.origin + '/event/noevent'
  }),

  computed: {
    ...sync('create', ['event'])
  },

  watch:{
    'event' (newVal) {
      console.log('event updated: ' + JSON.stringify(newVal))
    }
  },

  created () {
    this.refresh()
    this.editeditem = this.event
  },

  methods: {
    initialize () {},

    refresh () {      
      this.eventurl = window.location.origin + '#/play/event/' + this.event.code
    },
    
    saveItem () {
      if (this.editeditem.code === '') {
        this.editeditem.code = ApiService.generateId()
        this.eventurl = window.location.origin + '#/play/event/' + this.event.code
      }

      console.log('saving item: ' + JSON.stringify(this.editeditem))
      ApiService.put('data/events', this.editeditem)
        .then(response => {
          this.$notification.success('Sparat')
        }).catch(response => {
          this.$notification.error('Kunde inte spara: ' + response)
        })
    },
    
    deleteItem () {
      console.log('deleting item: ' + JSON.stringify(this.item))
      ApiService.delete('data/events/' + this.item.id)
        .then(response => {
          this.$notification.success('Borttaget')
          this.$router.go(-1)
        }).catch(response => {
          this.$notification.error('Misslyckades med att ta bort: ' + response)
        })
    },
  }
}
</script>
