<template>
  <v-card v-if="user.qrcode !== '' && user.state === 'new'">
    <v-card-text class="p-4">
      <v-img :src="qr" />
    </v-card-text>
  </v-card>
</template>

<script>
import { sync } from 'vuex-pathify'
import defaultQr from '@/assets/logo-notext.png'
import QRCode from 'qrcode'
export default {
  name: 'ActivateUserQr',

  props: {
    user: {
      type: Object,
      default: () => ({}),
    }
  },

  data: () => ({
    url: window.location.origin,
    qr: defaultQr
  }),

  computed: {
    ...sync('app', ['firstuse'])
  },

  watch:{
    'user' (newVal) {
      console.log('user updated: ' + JSON.stringify(newVal))
      this.refresh(newVal)
    }
  },

  created () {
    this.refresh(this.user)
  },

  methods: {
    initialize () {},

    async refresh (user) {
      let code = this.url + '/#/authenticate/' + user.qrcode
      this.qr = await QRCode.toDataURL(code)
      console.log('code: ' + code)
      console.log('user: ' + JSON.stringify(user))
    }
  }
}
</script>
