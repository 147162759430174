<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-1 text-no-wrap"
      mobile-breakpoint="10"
      :footer-props="{
        'items-per-page-text':'',
        'items-per-page-all-text':'Alla'
      }"
      @click:row="itemSelected"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="sök"
            single-line
            hide-details
          />
          <v-spacer />          
          <v-btn
            color="cyan accent-2"
            @click="onNewClick"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
    <!-- <v-btn
      fab
      color="cyan accent-2"
      bottom
      left
      absolute
      @click="onNewClick"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn> -->
  </v-container>
</template>

<script>
import { sync } from 'vuex-pathify'
import ApiService from '@/services/api.service'
export default {
  name: 'EventList',

  data: () => ({
    headers: [
      { text: 'Event', align: 'start', value: 'name', width: '60%' }
    ],
    items: [],
    search: ''
  }),

  computed: {
    ...sync('create', ['event'])
  },

  created () {
    this.initialize()
    this.refresh()
    console.log("event list created")
  },

  methods: {
    initialize () {},

    refresh () {
      this.loading = true
      ApiService.get('data/events')
        .then(response => {
          console.log(response.data.length + ' events fetched')
          this.data = response.data
          this.items = this.data;
        }).catch(response => {
          this.$notification.error('Failed to get events: ' + response)
        })
    },

    itemSelected (item) {
      this.event = item
      let c = this.$store.get('create')
      console.log('context in store: ' + JSON.stringify(c))
      console.log('list item clicked: ' + JSON.stringify(this.event))
      this.$router.push({ name: 'viewevent' })
    },

    onNewClick () {
      this.event = {}
      this.$router.push({ name: 'viewevent' })
    },
  }
}
</script>
