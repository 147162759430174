<template>
  <div class="mb-4">
    <v-row justify="center">
      <video
        ref="video"
        :width="width"
        :height="height"
        :src="source"
        :autoplay="autoplay"
        :playsinline="playsinline"
      />
    </v-row>
    <v-row
      justify="center"
      class="my-4"
    >
      <v-btn
        @click="onCapture"
      >
        Ta bild
      </v-btn>
    </v-row>
  </div>
</template>
<script>
// based from https://github.com/VinceG/vue-web-cam/
import axios from "axios";
import getUserMedia from "./getusermedia";

export default {
    name: "CameraCapture",
    props: {
        width: {
            type: [Number, String],
            default: "100%"
        },
        height: {
            type: [Number, String],
            default: "100%"
        },
        trimX: {
            type: [Number, String],
            default: 0
        },
        trimY: {
            type: [Number, String],
            default: 0
        },
        trimWidth: {
            type: [Number, String],
            default: 0
        },
        trimHeight: {
            type: [Number, String],
            default: 0
        },
        autoplay: {
            type: Boolean,
            default: true
        },
        screenshotFormat: {
            type: String,
            default: "image/jpeg"
        },
        // deviceId: {
        //     type: String,
        //     default: null
        // },
        playsinline: {
            type: Boolean,
            default: false
        },
        mediaConstraints: {
            type: Object,
            default: () => ({
                video: true,
                audio: false
            })
        },
        isFrontCam: {
            type: Boolean,
            default: true
        },
        maxSnapshot: {
            type: [Number],
            default: 3
        },
        debug: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            source: null,
            canvas: null,
            camerasListEmitted: false,
            cameras: [],
            imageCapture: {},
            captures: [],
            deviceId: null,
            imgReport: null,
            lastVideoMode: "deviceId",
            camsList: {
                back: null,
                front: null
            },
            inited: false
        };
    },
    computed: {
        supportFacingMode() {
            let result = "";
            if (
                navigator.mediaDevices.getSupportedConstraints()["facingMode"]
            ) {
                result = "Supported!";
            } else {
                result = "Not supported!";
            }
            return result;
        },
        Contraints() {
            const facingMode =
                this.mediaConstraints.video.facingMode ||
                (this.isFrontCam ? "user" : "environment");
            const video = {
                ...this.mediaConstraints.video,
                ...(this.deviceId
                    ? {
                          deviceId: { exact: this.deviceId }
                      }
                    : {}),
                facingMode
            };
            // alert(JSON.stringify(video))
            return {
                video,
                audio: this.mediaConstraints.audio
            };
        }
    },
    watch: {
        deviceId: function(newId, oldId) {
            if (newId !== oldId) {
                this.changeCamera(newId);
            }
        },
        isFrontCam: function(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.changeFrontBack(newValue);
            }
        },
        captures: function(value) {
            this.$emit("capturedImages", value);
        }
    },
    mounted() {
        this.setup();
    },
    methods: {
        loadSrcStream(stream) {
            if ("srcObject" in this.$refs.video) {
                // new browsers api
                try {
                    this.$refs.video.srcObject = stream;
                } catch (err) {
                    console.log(`loadSrcStream error=${err}`);
                }
            } else {
                // old broswers
                this.source = window.HTMLMediaElement.srcObject(stream);
            }
            this.video.onloadedmetadata = () => {
              this.video.play()?.then(_ => { this.$emit("started", stream); }).catch(e => {})
            };
            this.video.play()?.then(_ => { this.$emit("started", stream); }).catch(e => {})
        },
        async changeCamera(deviceId) {
            this.stop();
            this.deviceId = deviceId;
            await this.loadCamera();
            this.$emit("camera-change", deviceId);
        },
        async loadCameras() {
            try {
                const deviceInfos = await navigator.mediaDevices.enumerateDevices();
                if (this.debug) console.log(deviceInfos);
                deviceInfos.forEach(deviceInfo => {
                    if (deviceInfo.kind === "videoinput") {
                        this.cameras.push(deviceInfo);
                        if (deviceInfo.label.toLowerCase().indexOf("back") !== -1) {
                            this.camsList.back = deviceInfo;
                        }
                        if (deviceInfo.label.toLowerCase().indexOf("front") !== -1) {
                            this.camsList.front = deviceInfo;
                        }
                    }
                });

                if (!this.camerasListEmitted) {
                    this.$emit("cameras", this.cameras);
                    this.camerasListEmitted = true;
                }
            } catch (err) {
                this.$emit("notsupported", err);
                console.log(err);
            }
        },
        stopStreamedVideo(videoElem) {
            const stream = videoElem.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach(track => {
                // stops the video track
                track.stop();
                this.$emit("stopped", stream);
                this.$refs.video.srcObject = null;
                this.source = null;
            });
        },
        // Stop the video
        stop() {
            if (this.$refs.video !== null && this.$refs.video.srcObject) {
                if (this.debug) console.log("stoping");
                this.stopStreamedVideo(this.$refs.video);
            }
        },
        async setup() {
            await this.loadCameras();
            if (this.camsList.back !== null && !this.isFrontCam) {
              await this.changeCamera(this.camsList.back.deviceId)
            }

            if (this.autoplay) this.start();
        },
        // Start the video
        start() {
            this.loadCamera();
        },
        isMobile() {
            return typeof window.orientation !== "undefined";
        },
        loadCamera() {
            if (this.debug) console.log(this.Contraints);
            // navigator.mediaDevices.getUserMedia(this.Contraints, (err, stream) => {
            getUserMedia(this.Contraints, (err, stream) => {
                if (err) {
                    this.$emit("error", err);
                    console.log("failed to get user camera", err);
                    return;
                }
                // console.log('Got stream', stream)
                if (window.ImageCapture) {
                    const mediaStreamTrack = stream.getVideoTracks()[0];
                    this.imageCapture = new ImageCapture(mediaStreamTrack);
                }
                this.video = this.$refs.video;
                this.loadSrcStream(stream);
            });
        },
        async onCapture () {
            let image = await this.capture()
            this.$emit("capture", image)
            this.stop()
        },

        async capture() {
            this.canvas = this.getCanvas();
            const URL = this.canvas.toDataURL(this.screenshotFormat, 1);
            // this.saveSnapShot(URL);
            return URL;
        },

        getCanvas() {
            const video = this.$refs.video;
            if (!this.ctx) {
                const canvas = document.createElement("canvas");
                canvas.height = video.videoHeight;
                canvas.width = video.videoWidth;
                this.canvas = canvas;
                this.ctx = canvas.getContext("2d");
            }
            const { ctx, canvas } = this;
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            return canvas;
        },
    }
};
</script>