// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  event: {},
  map: {},
  marker: {},
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state)
}

const getters = {
  ...make.getters(state)
}

console.log('context store module initialized ...')

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
