<template>
  <v-container class="fill-height justify-center">
    <v-responsive
      class="align-center text-center fill-height"
      max-width="400"
    >
      <v-img
        class="mx-10"
        src="@/assets/logo.svg"
      />

      <div class="text-body-2 font-weight-light mt-3">
        Welcome to
      </div>

      <h1 class="text-h2 font-weight-bold">
        Geoplay Creator
      </h1>

      <div class="py-5" />

      <v-form>
        <v-row class="d-flex align-center justify-center">
          <v-col cols="10">
            <v-text-field
              v-model="username"
              label="Username"
              required
            />
            <v-text-field
              v-model="password"
              label="Password"
              type="password"
              required
            />
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
          <v-col cols="10">
            <v-btn
              color="primary"
              min-width="228"
              rel="noopener noreferrer"
              size="x-large"
              target="_blank"
              variant="flat"
              @click="onLogin"
            >
              <v-icon
                icon="mdi-login"
                size="large"
                start
              />

              Login
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-responsive>
  </v-container>
</template>

<script>
import ApiService from '@/services/api.service'

export default {
  name: 'LoginView',

  components: {
  },

  data: () => ({
    username: '',
    password: '',
  }),

  created () {
    console.log('login view created')
    this.refresh()
  },

  methods: {
    initialize () {},

    refresh () {
      this.loading = true
    },

    onLogin () {
      console.log('username: ' + this.username)
      const creds = { username: this.username, password: this.password }
      
      ApiService.post('/auth/login', creds)
        .then(response => {
          console.log('response: ' + JSON.stringify(response.data))
          var item = response.data
          this.$notification.success('Användare inloggad')
          this.dialog = false
        }).catch(response => {
          this.$notification.error('Inloggning misslyckades: ' + response)
        })
    },
  }
}
</script>