<template>
  <v-card>
    <v-card-title>Markör</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="editeditem.name"
        label="Namn"
        required
      />
      <v-textarea
        v-model="editeditem.description"
        label="Beskrivning"
        required
      />
      <v-select
        v-model="editeditem.type"
        :items="types"
        item-text="name"
        item-value="value"
        label="Typ"
        outlined
        @change="onTypeChange"
      />
      <qr-code-item
        v-if="editeditem.type === 'qrcode'"
        :code="marker.code"
        @code-changed="onNewQRCode"
      />
    </v-card-text>
    <v-card-text
      v-if="editeditem.type === 'qrcode' || editeditem.type === 'info'"
      align="center"
    >
      <v-img
        v-if="image"
        class="mt-2 mx-2"
        :src="image"
      />
      <image-uploader
        :maxWidth="2048"
        :maxHeight="2048"
        :preview="false"
        :className="'d-none'"
        capture="user"
        outputFormat="file"
        @input="setImage"
      >        
        <label
          slot="upload-label"
          for="fileInput"
        >
          <v-icon size="large">
            mdi-camera
          </v-icon>
          <h2>Ändra bild</h2>
        </label>
      </image-uploader>
    </v-card-text>
    <v-card-text v-if="editeditem.type === 'code'">
      <v-text-field
        v-model="editeditem.maincluetext"
        label="Ange en numerisk kod"
        required
      />
    </v-card-text>
    <v-card-text v-if="editeditem.type === 'question'">
      <v-text-field
        v-model="editeditem.maincluetext"
        label="Ange ett entydigt svar på ett ord"
        required
      />
    </v-card-text>
    <v-card-actions>
      <v-row
        justify="space-around"
        class="mb-3"
      >
        <v-btn
          @click="$router.go(-1)"
        >
          Avbryt
        </v-btn>
        <v-btn
          color="error"
          @click="deleteItem()"
        >
          Ta bort
        </v-btn>
        <v-btn
          color="success"
          @click="saveItem()"
        >
          Spara
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { sync } from 'vuex-pathify'
import ApiService from '@/services/api.service'
import defaultImage from '@/assets/logo-notext.png'
import ImageUploader from 'vue-image-upload-resize'

export default {
  name: 'MarkerItem',

  components: {
    ImageUploader
  },

  data: () => ({
    editeditem: {},
    image: {},
    showcam: false,
    types: [
      {name: 'QR kod', value: 'qrcode'},
      {name: 'Kod', value: 'code'},
      {name: 'Fråga', value: 'question'},
      {name: 'Information', value: 'info'},
    ],
  }),

  computed: {
    ...sync('create', ['event', 'map', 'marker'])
  },

  created () {
    this.refresh()
  },

  methods: {
    initialize () {},

    refresh () {
      if (!this.marker.type) this.marker.type = 'qrcode'
      this.editeditem = this.marker
      if (this.marker.mainclueimage) {
        this.image = window.location.origin + '/api/resource/' + this.marker.mainclueimage
      }
    },
    
    saveItem () {
      this.editeditem.mapid = this.map.id
      console.log('saving item: ' + JSON.stringify(this.editeditem))
      ApiService.put('data/markers', this.editeditem)
        .then(response => {
          this.$notification.success('Markör sparad')
        }).catch(response => {
          this.$notification.error('Kunde inte spara: ' + response)
        })
    },
    
    deleteItem () {
      console.log('deleting item: ' + JSON.stringify(this.editeditem))
      ApiService.delete('data/markers/' + this.editeditem.id)
        .then(response => {
          this.$notification.success('Borttaget')
          this.$router.go(-1)
        }).catch(response => {
          this.$notification.error('Misslyckades med att ta bort: ' + response)
        })
    },

    onNewQRCode (newcode) {
      this.marker.code = newcode
    },    

    onMarkerPictureChange (file) {
      console.log('file: ' + eval(file))
      console.log('file.name: ' + JSON.stringify(file.name))
      
      ApiService.uploadFile('resource/upload', file)
        .then(response => {
          this.editeditem.mainclueimage = response.data.Filename
          this.image = window.location.origin + '/api/resource/' + this.editeditem.mainclueimage
        }).catch(response => {
          this.$notification.error('Failed to upload team image: ' + response.message)
        })
    },

    setImage: function(imagefile) {
      this.onMarkerPictureChange(imagefile)
    },

    onTypeChange (e) {
      console.log('this.marker.type: ' + this.marker.type)
      console.log('this.editeditem.type: ' + this.editeditem.type)
    }
  }
}
</script>

<style>
.mdi-camera::before {
  font-size: 4rem;
}
</style>
