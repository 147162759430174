<template>
  <div>
    <search-add-bar
      @new="onNew"
      @search="onSearchChange"
    />
    <v-switch
      v-model="disable"
      label="Redigera innehåll"
    />
    <v-list>
      <v-list-item-group
        color="primary"
      >
        <draggable
          v-model="items"
          :disabled="disable"
          @change="onOrderChanged"
        >
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="itemSelected(item)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </draggable>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ApiService from '@/services/api.service'
export default {
  name: 'DataList',

  components: {
    draggable,
  },

  props: {
    type: {
      type: String,
      default: () => ('log'),
    },
    itemview: {
      type: String,
      default: () => ('viewlog'),
    },
  },

  data: () => ({
    items: [],
    allitems: [],
    search: '',
    disable: true,
  }),

  created () {
    this.refresh()
  },

  methods: {
    initialize () {},

    refresh () {
      this.loading = true
      ApiService.get('data/' + this.type)
        .then(response => {
          this.items = response.data.sort((a,b) => {return a.order - b.order})
          this.allitems = this.items
          this.search = ''
        }).catch(response => {
          this.$notification.error('Failed to get items: ' + response)
        })
    },

    itemSelected (item) {
      this.$emit('selected', item)
    },

    onOrderChanged () {
      var count = this.items?.length || 0;
      while (count--) this.items[count].order = count + 1
      
      ApiService.put('data/array/' + this.type, this.items)
        .then(response => {
          this.items = response.data.sort((a,b) => {return a.order - b.order})
        }).catch(response => {
          this.$notification.error('Failed to save items: ' + response)
        })
    },

    onNew () {
      this.$emit('new')
    },

    onSearchChange (search) {
      this.disable = search != ''
      if (this.disable) {
        this.items = this.items.filter(i => i.name.toLowerCase().includes(search.toLowerCase()))
      } else {
        this.items = this.allitems
      }
    }
  }
}
</script>
