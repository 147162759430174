<template>
  <v-app>
    <TopToolbar v-if="!firstuse" />
    <v-main>
      <router-view />
    </v-main>
    <BottomNav v-if="false" />
  </v-app>
</template>

<script>
import { sync } from 'vuex-pathify'
import TopToolbar from './components/TopToolbar.vue'
import BottomNav from './components/BottomNav.vue'

export default {
  name: 'App',
  components: {
    TopToolbar,
    BottomNav
  },

  data: () => ({
  }),

  computed: {
    ...sync('app', ['firstuse', 'token'])
  },

  created () {
  }
}
</script>
