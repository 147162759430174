<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-1 text-no-wrap"
      mobile-breakpoint="10"
      :footer-props="{
        'items-per-page-text':'',
        'items-per-page-all-text':'Alla'
      }"
      @click:row="itemSelected"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Fritext sökning"
            single-line
            hide-details
          />
          <v-spacer />          
          <v-btn
            color="cyan accent-2"
            @click="onNewClick"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'
import ApiService from '@/services/api.service'
export default {
  name: 'MapList',

  data: () => ({
    headers: [
      { text: 'Kartor', align: 'start', value: 'name', width: '60%' }
    ],
    items: [],
    search: ''
  }),

  computed: {
    ...sync('create', ['event', 'map'])
  },

  created () {
    this.refresh()
    console.log("map list created")
  },

  methods: {
    initialize () {},

    refresh () {
      if (this.event.id > 0) {
        this.items = this.event.maps;
        ApiService.get('data/maps/field/event_id/' + this.event.id)
          .then(response => {
            console.log(response.data.length + ' maps fetched')
            this.data = response.data
            this.items = this.data;
          }).catch(response => {
            this.$notification.error('Failed to get events: ' + response)
          })
        }
    },

    itemSelected (item) {
      this.map = item
      console.log('list item clicked: ' + JSON.stringify(this.map))
      this.$router.push({ name: 'viewmap' })
    },

    onNewClick () {
      this.map = {}
      this.$router.push({ name: 'viewmap' })
    },
  }
}
</script>
