<template>
  <div>
    <search-add-bar
      @new="onNew"
      @search="onSearchChange"
    />
    <v-switch
      v-model="disable"
      label="Redigera innehåll"
    />
    <v-list>
      <v-list-item-group
        color="primary"
      >
        <draggable
          v-model="filtereditems"
          :disabled="disable"
          @end="onOrderChanged"
        >
          <template v-for="(item, index) in filtereditems">
            <div :key="index">
              <v-divider />
              <v-list-item
                @click="itemSelected(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.order }}. {{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>
          <v-divider />
        </draggable>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'ItemList',

  components: {
    draggable,
  },

  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },

  data: () => ({
    filtereditems: [],
    search: '',
    disable: true,
  }),

  watch:{
    'items' () {
      this.refresh()
    }
  },

  created () {
    this.refresh()
  },

  methods: {
    initialize () {},

    refresh () {
      if (this.items !== null) {
        this.loading = true
        this.filtereditems = [...this.items]
        this.filtereditems.sort((a,b) => {return a.order - b.order})
      }
    },

    itemSelected (item) {
      this.$emit('selected', item)
    },

    onOrderChanged () {
      var count = this.filtereditems?.length || 0
      while (count--) this.filtereditems[count].order = count + 1
      this.$emit('changed', this.filtereditems)
    },

    onNew () {
      this.$emit('new')
    },

    onSearchChange (search) {
      this.disable = search != ''
      if (this.disable) {
        this.filtereditems = this.filtereditems.filter(i => i.name.toLowerCase().includes(search.toLowerCase()))
      } else {
        this.filtereditems = this.items
      }
    }
  }
}
</script>
