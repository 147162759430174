<template>
  <v-bottom-navigation
    v-model="activeItem"
    app
    fixed
    grow
    color="primary"
  >
    <v-btn
      value="home"
      to="/"
    >
      <span>Hem</span>
      <v-icon>home</v-icon>
    </v-btn>

    <v-btn
      value="about"
      to="/about"
    >
      <span>Om</span>
      <v-icon>info</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script lang="ts">
export default {
  name: 'BottomNav',

  data: () => ({
    activeItem: {}
  })
}
</script>
