<template>
  <v-container>
    <v-row align="center">
      <v-col :cols="cols[0]">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Sök"
          single-line
          hide-details
          @keyup="onSearchChange"
        />
      </v-col>
      <v-col :cols="cols[1]">
        <v-btn
          color="cyan accent-2"
          @click="onNewClick"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SearchAddBar',

  data: () => ({
    search: '',
  }),

  computed: {
    cols () {
      const result = this.$vuetify.breakpoint.name === 'lg' ? [11, 1] : (this.$vuetify.breakpoint.name === 'xs' ? [9, 3] : [10, 2])
      return result
    },
  },

  created () {
  },

  methods: {
    onNewClick () {
      this.$emit('new')
    },

    onSearchChange () {
      this.$emit('search', this.search)
    }
  }
}
</script>
