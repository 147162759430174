import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/services/jwt.service'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = window.location.origin
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader () {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`
    let token = JwtService.getToken();
    console.log('jwt service token: ' + token)
    if ( token && token !== '') Vue.axios.defaults.headers.common.token = `${token}`
  },

  query (resource, params) {
    resource = 'api/' + resource
    return Vue.axios.get(resource, params).catch(error => {
      if (error.response.status === 401) JwtService.destroyToken()
      throw error
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get (resource) {
    ApiService.setHeader()
    resource = 'api/' + resource
    return Vue.axios.get(`${resource}`).catch(error => {
      if (error.response.status === 401) JwtService.destroyToken()
      throw error
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post (resource, params) {
    ApiService.setHeader()
    resource = 'api/' + resource
    return Vue.axios.post(`${resource}`, params).catch(error => {
      if (error.response.status === 401) JwtService.destroyToken()
      throw error
    })
  },

  /**
   * Set the POST HTTP request for upload
   * @param resource
   * @param files
   * @returns {*}
   */
  upload (resource, files) {
    ApiService.setHeader()
    resource = 'api/' + resource
    var formData = new FormData()
    for (var i = 0; i < files.length; i++) { formData.append('file', files[i]) }
    return Vue.axios.post(`${resource}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8'
      }
    }).catch(error => {
      throw error
    })
  },

  /**
   * Set the POST HTTP request for upload
   * @param resource
   * @param files
   * @returns {*}
   */
  uploadFile (resource, file) {
    ApiService.setHeader()
    resource = 'api/' + resource
    var formData = new FormData()
    formData.append('file', file)
    return Vue.axios.post(`${resource}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8'
      }
    }).catch(error => {
      throw error
    })
  },
  
  /**
   * Set the POST HTTP request for upload
   * @param resource
   * @param files
   * @returns {*}
   */
  uploadImage (resource, image) {
    ApiService.setHeader()
    let files = []
    let file = this.dataURLtoFile(image, "image.jpg")
    files.push(file)
    return this.upload(resource, files)
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update (resource, slug, params) {
    ApiService.setHeader()
    resource = 'api/' + resource
    return Vue.axios.put(`${resource}/${slug}`, params).catch(error => {
      if (error.response.status === 401) JwtService.destroyToken()
      throw error
    })
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put (resource, params) {
    ApiService.setHeader()
    resource = 'api/' + resource
    return Vue.axios.put(`${resource}`, params).catch(error => {
      if (error.response.status === 401) JwtService.destroyToken()
      throw error
    })
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete (resource) {
    ApiService.setHeader()
    resource = 'api/' + resource
    return Vue.axios.delete(resource).catch(error => {
      if (error.response.status === 401) JwtService.destroyToken()
      throw error
    })
    // .catch(error => {
    //   throw new Error(`[RWV] ApiService ${error}`)
    // })
  },

  dec2hex (dec) {
    return dec.toString(16).padStart(2, "0")
  },

  // generateId :: Integer -> String
  generateId (len) {
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, this.dec2hex).join('')
  },
  
  dataURLtoFile(dataURL, filename) {
    let arr = dataURL.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  },
}

export default ApiService
