<template>
  <v-card>
    <v-card-title>Karta</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="editeditem.name"
        label="Namn"
        required
      />
      <v-textarea
        v-model="editeditem.description"
        label="Beskrivning"
        required
      />
      <v-card-actions>
        <v-row justify="space-around" class="mb-3">
          <v-btn
            @click="$router.go(-1)"
          >
            Avbryt
          </v-btn>
          <v-btn 
            color="error"
            @click="deleteItem()"
          >
            Ta bort
          </v-btn>
          <v-btn
            color="success"
            @click="saveItem()"
          >
            Spara
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card-text>
    <qr-code-item
      v-if="editeditem.code"
      :code="gameurl"
      readonly
    />
  </v-card>
</template>

<script>
import { sync } from 'vuex-pathify'
import ApiService from '@/services/api.service'

export default {
  name: 'MapItem',

  data: () => ({
    editeditem: {},
    gameurl: window.location.origin + '/map/nomap'
  }),

  computed: {
    ...sync('create', ['event', 'map'])
  },

  created () {
    this.refresh()
  },

  methods: {
    initialize () {},

    refresh () {
      if (!this.map) this.map = {}
      if (this.map.markers) this.map.markers.sort(function(a,b) {return a.order - b.order})
      this.editeditem = this.map
      this.gameurl = window.location.origin + '#/play/map/' + this.map.code
    },
    
    saveItem () {
      this.editeditem.eventid = this.event.id
      if (this.editeditem.id && this.editeditem.id > 0) {
        ApiService.put('data/maps', this.editeditem)
          .then(response => {
            this.$notification.success('Karta sparad')
          }).catch(response => {
            this.$notification.error('Kunde inte spara: ' + response)
          })
      } else {
        this.editeditem.code = ApiService.generateId()
        ApiService.post('data/maps', this.editeditem)
          .then(response => {
            this.$notification.success('Karta skapad')
          }).catch(response => {
            this.$notification.error('Kunde inte spara: ' + response)
          })
      }
    },
    
    deleteItem () {
      ApiService.delete('data/maps/' + this.editeditem.id)
        .then(response => {
          this.$notification.success('Karta borttagen')
          this.$router.go(-1)
        }).catch(response => {
          this.$notification.error('Misslyckades med att ta bort: ' + response)
        })
    },
  }
}
</script>
