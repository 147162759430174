<template>
  <v-card>
    <v-card-title>Inställningar</v-card-title>
    <v-card-text>
      <p>Dina uppgifter används dels för att sortera ut dina arbetsordrar samt för att skicka med underlaget du fyller i.</p>
      <v-text-field
        :value="user.username"
        label="Användarnamn"
        required
      />
    </v-card-text>
    <v-card-actions>
      <v-row justify="space-around">
        <v-btn 
          text
          @click="$router.go(-1)"
        >
          Avbryt
        </v-btn>
        <v-btn 
          text
          color="error"
          to="/"
        >
          Ta bort
        </v-btn>
        <v-btn
          text
          color="success"
          @click="saveAndredirectToHome()"
        >
          Spara
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'UserItem',

  props: {
    user: {
      type: Object,
      default: () => ({}),
    }
  },

  data: () => ({
  }),

  computed: {
    ...sync('app', ['firstuse'])
  },

  created () {
    this.refresh()
  },

  methods: {
    initialize () {},

    refresh () {
    }
  }
}
</script>
