<template>
  <v-card>
    <v-card-text>
      <v-btn
        color="primary"
        @click="dialog = !dialog"
      >
        Ta bild
      </v-btn>
      <v-img
        :src="newimage"
      />
    </v-card-text>
    <v-dialog
      v-model="dialog"
    >
      <WebCam
        ref="webcam"
        width="100%"
        height="100%"
        :autoplay="false"
        :deviceId="deviceId"
        @cameras="onCamerasLoaded"
      />
      <!-- <WebCam
        ref="webcam"
        :autoplay="autoplay"
        :deviceId="deviceId"
        width="100%"
        height="100%"
        :isFrontCam="frontCam"
        @cameras="onCamerasLoaded"
      /> -->
      <v-btn
        color="success"
        @click="onCapture"
      >
        Ta bild
      </v-btn>
    </v-dialog>
  </v-card>
</template>

<script>
import { sync } from 'vuex-pathify'
import { WebCam } from 'vue-cam-vision'
import defaultImage from '@/assets/logo-notext.png'
export default {
  name: 'PictureItem',

  components: {    
    WebCam,
  },

  props: {
    image: {
      type: String,
      default: () => (defaultImage),
    },
  },

  data: () => ({
    deviceId: null,
    cameras: [],
    webcam: null,
    dialog: false,
    newimage: defaultImage,
  }),

  computed: {
    ...sync('create', ['event', 'map', 'marker']),
  },

  watch: {
    dialog: function() {
      if (this.$refs.webcam && this.$refs.webcam !== null) {
        if (!this.dialog) this.$refs.webcam.stop()
        if (this.dialog) this.$refs.webcam.start()
      }
    },
    image: function() {
      this.newimage = this.image
    }
  },

  created () {
    this.refresh()
  },

  methods: {
    initialize () {},

    async refresh () {
    },

    async onCapture () {
      this.newimage = await this.$refs.webcam.capture()
      this.$emit('capture', this.newimage)
      this.dialog = false
    },

    async onCamerasLoaded (cameras) {
      console.log('loaded cameras: ' + JSON.stringify(cameras))
      this.$refs.webcam.stop()
      this.cameras = cameras
      let deviceId = cameras[cameras.length-1].deviceId
      if (deviceId !== this.$refs.webcam.deviceId) {
        this.$refs.webcam.stop()
        console.log('changing camera from ' + this.deviceId + ', to ' + deviceId)
        this.$refs.webcam.changeCamera(deviceId)
      }
      // this.$refs.webcam.stop()
      // this.$refs.webcam.start()
    }
  }
}
</script>
